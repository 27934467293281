export enum NoClaimsDiscountTypeEnum {
    BuildingsNCD = 0,
    ContentsNCD = 1,
};

export enum NoClaimsDiscountYearEnum {
    None = 1,
    _1Year = 3,
    _2Years = 4,
    _3Years = 5,
    _4Years = 6,
    _5Years = 7,
    MoreThan5Years = 8,
};