import React from "react";
import { StateProvider } from './src/hooks/StateContext';
import { ApplicationStateInitial } from './src/models/ApplicationState';
import { ApplicationReducer } from './src/reducers/ApplicationReducer';
import InjectProvider from './src/components/InjectProvider';
import "./src/css/typography.css";

export const onRenderBody = ({ setPostBodyComponents }) => {
  if (process.env.NODE_ENV === `production`) {
    return setPostBodyComponents([
      <script
        key={`livechat`}
        dangerouslySetInnerHTML={{
          __html: `
            window.__lc = window.__lc || {};
            window.__lc.license = 10210472;
            (function() {
              var lc = document.createElement('script'); lc.type = 'text/javascript'; lc.async = true;
              lc.src = ('https:' == document.location.protocol ? 'https://' : 'http://') + 'cdn.livechatinc.com/tracking.js';
              var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(lc, s);
            })();
          `,
        }}
      />,
    ])
  }
  return null;
};

export const wrapRootElement = ({ element }) => {
  return (
    <StateProvider initialState={ApplicationStateInitial} reducer={ApplicationReducer}>
      {element}
    </StateProvider>
  )
};

export const wrapPageElement = InjectProvider;