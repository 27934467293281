import EligibilityQuestion, {
    EligibilityQuestionGroup,
} from '../components/Eligibility/EligibilityQuestionsModel';

export const groupEligibilityQuestions = (
    questions: EligibilityQuestion[],
): EligibilityQuestionGroup[] => {
    const questionsLeft = [...questions];
    const questionGrouping: EligibilityQuestionGroup[] = [];
    const groupings = [
        'The property',
        'The home to be insured',
        'You or anyone living with you have NOT',
    ];

    groupings.forEach((name: string, index: number) => {
        const questionsInGroup: EligibilityQuestion[] = [];
        for (let index = 0; index < questionsLeft.length; index++) {
            const itemIsInGroup = questionsLeft[index].Question.startsWith(name);
            if (itemIsInGroup) {
                let addQuestion = questionsLeft.splice(index, 1)[0];
                addQuestion.Question = addQuestion.Question.replace(name, '').trim();
                questionsInGroup.push(addQuestion);
                index--;
            }
        }
        questionGrouping.push({
            id: index,
            name: name,
            agreed: false,
            questions: questionsInGroup,
        });
    });

    return questionGrouping;
};
