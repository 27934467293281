import * as Yup from 'yup';
import { calculateAge } from '../utils';

export interface ApplicantDetailsModel {
    Title: string;
    Forenames: string;
    Surname: string;
    DateOfBirth: string;
    EmailAddress?: string;
    TelephoneNumber?: string;
}

export interface ApplicantContactDetailsModel {
    EmailAddress?: string;
    TelephoneNumber?: string;
}

export const ApplicantDetailsInitial: ApplicantDetailsModel = {
    Title: '',
    Forenames: '',
    Surname: '',
    DateOfBirth: '',
    EmailAddress: '',
    TelephoneNumber: '',
};

export interface ApplicantDetailsTouchedModel {
    Title: boolean;
    Forenames: boolean;
    Surname: boolean;
    DateOfBirth: boolean;
    EmailAddress: boolean;
    TelephoneNumber: boolean;
}

export interface AllApplicantDetails {
    Client1: ApplicantDetailsModel;
    Client2: ApplicantDetailsModel;
}

export const AllApplicantDetailsInitial: AllApplicantDetails = {
    Client1: { ...ApplicantDetailsInitial },
    Client2: { ...ApplicantDetailsInitial },
};

export const nameRegex = new RegExp(/^(?:(?![×Þß÷þø])[-a-zÀ-ÿ'])+$/, 'i');
export const dobRegex = new RegExp(/\d{2}\s?\/\s?\d{2}\s?\/\s?\d{4}/);
const telephoneRegex = new RegExp(/^(?:0|\+?44)(?:\d\s?){9,10}$/);

export const ApplicantDetailsValidationSchema = {
    Title: Yup.string().required('Title is required'),
    Forenames: Yup.string()
        .matches(nameRegex, 'Please enter a valid forename')
        .required('Forename is required'),
    Surname: Yup.string()
        .matches(nameRegex, 'Please enter a valid surname')
        .required('Surname is required'),
    DateOfBirth: Yup.string()
        .test('test-minimum-age', 'Must be at least the age of 18', function (value) {
            return calculateAge(value) >= 18;
        })
        .test('test-maximum-age', 'Applicant is too old', function (value) {
            return calculateAge(value) < 110;
        })
        .required('Date of birth is required'),
};

export const contactDetailsValidationSchema = {
    TelephoneNumber: Yup.string()
        .required('Telephone number is required')
        .matches(telephoneRegex, 'Please enter a valid telephone number'),
    EmailAddress: Yup.string()
        .email('Please enter a valid email')
        .required('Email address is required'),
};

export const OneApplicantDetailsValidationSchema = Yup.object({
    ...ApplicantDetailsValidationSchema,
});

export const TwoApplicantDetailsValidationSchema = Yup.object({
    Client1: Yup.object({
        ...ApplicantDetailsValidationSchema,
    }),
    Client2: Yup.object({
        ...ApplicantDetailsValidationSchema,
    }),
});
