import { AddressDetailsInitial, AddressDetailsModel } from "../components/Address/AddressDetailsModel";
import { ApplicantDetailsInitial, ApplicantDetailsModel } from "../components/Applicant/ApplicantDetailsModel";
import { CoverTypeOption } from "../components/CoverType/CoverTypeOptionEnum";
import { PossessionsModel } from "../components/Possessions/PossessionsModel";

export interface QuoteDetailsState {
    Client1: ApplicantDetailsModel;
    Client2: ApplicantDetailsModel | undefined;
    RiskAddress: AddressDetailsModel;
    CorrespondenceAddress: AddressDetailsModel | undefined;
    NumberOfBedrooms: string;
    PropertyTypeId: string;
    YearBuiltRange: string;
    SelectedCoverType: CoverTypeOption;
    IncludeBuildings: boolean;
    IncludeContents: boolean;
    IncludeContentsPersonalPossessions: boolean;
    ContentsPersonalPossessions: PossessionsModel[];
    ContentsPersonalUnspecifiedValue: string,
    BuildingsExcessId: number;
    ContentsExcessId: number;
    BuildingsNoClaimsDiscountId: number;
    ContentsNoClaimsDiscountId: number;
    BuildingsAccidentalCover: boolean;
    ContentsAccidentalCover: boolean;
    HomeEmergencyCover: boolean;
    LegalProtectionCover: boolean;
};

export const InitialQuoteDetails: QuoteDetailsState = {
    Client1: ApplicantDetailsInitial,
    Client2: ApplicantDetailsInitial,
    RiskAddress: AddressDetailsInitial,
    CorrespondenceAddress: AddressDetailsInitial,
    NumberOfBedrooms: '',
    PropertyTypeId: '',
    YearBuiltRange: '',
    SelectedCoverType: CoverTypeOption.BuildingsAndContentsCover,
    IncludeBuildings: true,
    IncludeContents: true,
    IncludeContentsPersonalPossessions: false,
    ContentsPersonalPossessions: [],
    ContentsPersonalUnspecifiedValue: "",
    BuildingsExcessId: 4, // £250
    ContentsExcessId: 4, // £250
    BuildingsNoClaimsDiscountId: 7, // 5 years
    ContentsNoClaimsDiscountId: 7, // 5 years
    BuildingsAccidentalCover: false,
    ContentsAccidentalCover: false,
    HomeEmergencyCover: false,
    LegalProtectionCover: false,
}