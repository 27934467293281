import { AddressDetailsModel, AllAddressDetails } from '../components/Address/AddressDetailsModel';
import {
    ApplicantContactDetailsModel,
    ApplicantDetailsInitial,
    ApplicantDetailsModel,
} from '../components/Applicant/ApplicantDetailsModel';
import { CoverTypeOption } from '../components/CoverType/CoverTypeOptionEnum';
import {
    EligibilityQuestionGroup,
    EligibilityAgreementModel,
} from '../components/Eligibility/EligibilityQuestionsModel';
import { ExcessTypeEnum } from '../components/Excess/ExcessTypeEnum';
import {
    NoClaimsDiscountTypeEnum,
    NoClaimsDiscountYearEnum,
} from '../components/NoClaimsDiscount/NoClaimsDiscountTypeEnum';
import { PaymentDetailsModel } from '../components/Payment/PaymentDetailsModel';
import { PossessionsModel } from '../components/Possessions/PossessionsModel';
import { PropertyType } from '../components/Property/PropertyDetailsEnum';
import { PropertyDetailsModel } from '../components/Property/PropertyDetailsModel';
import {
    QuoteResultModel,
    SelectPremiumModel,
} from '../components/QuoteResults/SelectPremiumModel';
import {
    GetContentsPersonalUnspecifiedValue,
    GetExcessId,
    GetYearBuiltRange,
    RetrieveQuoteResponse,
} from '../components/RetrieveQuote/RetrieveQuoteResponse';
import { RetrieveQuoteStatusEnum } from '../components/RetrieveQuote/RetrieveQuoteStatusEnum';
import { formatPostcode, getFormattedDate } from '../components/utils';
import { Action } from '../models/Action';
import { ActionType } from '../models/ActionType';
import { ApplicationState, ApplicationStateInitial } from '../models/ApplicationState';
import { groupEligibilityQuestions } from './EligibilityFunctions';

export const initialApplicationState: ApplicationState = ApplicationStateInitial;

const setDeepValue = (
    state: any,
    pathSplit: string[],
    value: string | Date | AddressDetailsModel,
): any => {
    const newPathSplit = pathSplit.slice(1);
    if (newPathSplit.length > 0) {
        return {
            ...state,
            [pathSplit[0]]: {
                ...state[pathSplit[0]],
                [pathSplit[1]]: setDeepValue(state[pathSplit[0]], newPathSplit, value),
            },
        };
    }
    return value;
};

const capitalizeAndTrim = (value: string) => {
    if (typeof value !== 'string') {
        return '';
    }
    const parts = value.split(' ');
    const capitalized = parts.map((part) => {
        return part.charAt(0).toUpperCase() + part.slice(1);
    });
    return capitalized.join(' ').trim();
};

export function ApplicationReducer(state: ApplicationState, action: Action): ApplicationState {
    switch (action.type) {
        case ActionType.SetCorrespondenceAddress: {
            const value = action.value! as AddressDetailsModel;
            return {
                ...state,
                CorrespondenceAddress: {
                    AddressLine1: capitalizeAndTrim(value.AddressLine1),
                    AddressLine2: capitalizeAndTrim(value.AddressLine2),
                    AddressLine3: capitalizeAndTrim(value.AddressLine3),
                    AddressLine4: capitalizeAndTrim(value.AddressLine4),
                    Postcode: formatPostcode(value.Postcode),
                },
            };
        }
        case ActionType.UpdateCoverType: {
            switch (action.value) {
                case CoverTypeOption.BuildingsAndContentsCover:
                    return {
                        ...state,
                        IncludeBuildings: true,
                        IncludeContents: true,
                        SelectedCoverType: CoverTypeOption.BuildingsAndContentsCover,
                    };
                case CoverTypeOption.BuildingsOnlyCover:
                    return {
                        ...state,
                        IncludeBuildings: true,
                        IncludeContents: false,
                        SelectedCoverType: CoverTypeOption.BuildingsOnlyCover,
                    };
                case CoverTypeOption.ContentsOnlyCover:
                    return {
                        ...state,
                        IncludeBuildings: false,
                        IncludeContents: true,
                        SelectedCoverType: CoverTypeOption.ContentsOnlyCover,
                    };
            }
        }
        case ActionType.UpdateAgreeDeclarations: {
            return { ...state, AgreeDeclarations: action.value as boolean };
        }
        case ActionType.UpdateAgreeDeclarationsGroup: {
            const eligiblityAgreement = action.value as EligibilityAgreementModel;
            const updatedGrouping = [...state.EligibilityGrouping].map(
                (group: EligibilityQuestionGroup) => {
                    return group.id === eligiblityAgreement.Id
                        ? { ...group, agreed: eligiblityAgreement.Value }
                        : group;
                },
            );

            return {
                ...state,
                EligibilityGrouping: updatedGrouping,
            };
        }
        case ActionType.UpdateFullPropertyDetails: {
            const propertyDetails = action.value! as PropertyDetailsModel;
            return {
                ...state,
                NumberOfBedrooms: propertyDetails.NumberOfBedrooms,
                PropertyTypeId: propertyDetails.PropertyTypeId,
                YearBuiltRange: propertyDetails.YearBuiltRange,
                ReloadAddress: false,
            };
        }
        case ActionType.UpdateNumberOfBedrooms: {
            const numberOfBedrooms = action.value! as string;
            return {
                ...state,
                NumberOfBedrooms: numberOfBedrooms,
            };
        }
        case ActionType.UpdateTypeOfProperty: {
            const typeOfProperty = action.value! as string;
            return {
                ...state,
                PropertyTypeId: typeOfProperty,
            };
        }
        case ActionType.UpdateYearOfConstruction: {
            const yearOfConstruction = action.value! as string;
            return {
                ...state,
                YearBuiltRange: yearOfConstruction,
            };
        }
        case ActionType.SetContactDetails: {
            const value = action.value! as ApplicantContactDetailsModel;
            return {
                ...state,
                Client1: {
                    ...state.Client1,
                    EmailAddress: value.EmailAddress,
                    TelephoneNumber: value.TelephoneNumber,
                },
            };
        }
        case ActionType.UpdateAllAddressDetails: {
            const value = action.value! as AllAddressDetails;
            return {
                ...state,
                RiskAddress: {
                    AddressLine1: capitalizeAndTrim(value.RiskAddress.AddressLine1).trim(),
                    AddressLine2: capitalizeAndTrim(value.RiskAddress.AddressLine2).trim(),
                    AddressLine3: capitalizeAndTrim(value.RiskAddress.AddressLine3).trim(),
                    AddressLine4: capitalizeAndTrim(value.RiskAddress.AddressLine4).trim(),
                    Postcode: formatPostcode(value.RiskAddress.Postcode),
                },
                CorrespondenceAddress: {
                    AddressLine1: capitalizeAndTrim(value.CorrespondenceAddress.AddressLine1),
                    AddressLine2: capitalizeAndTrim(value.CorrespondenceAddress.AddressLine2),
                    AddressLine3: capitalizeAndTrim(value.CorrespondenceAddress.AddressLine3),
                    AddressLine4: capitalizeAndTrim(value.CorrespondenceAddress.AddressLine4),
                    Postcode: formatPostcode(value.CorrespondenceAddress.Postcode),
                },
                ReloadAddress: true,
                IsRiskAddressConfirmed: false,
            };
        }
        case ActionType.SetRiskAddressConfirmed: {
            const value = action.value! as boolean;
            return {
                ...state,
                IsRiskAddressConfirmed: value,
            };
        }
        case ActionType.UpdatePaymentDetails: {
            const value = action.value! as PaymentDetailsModel;
            return {
                ...state,
                Apply: {
                    ...value,
                    PaidYearly: value.Payment === 'Annually',
                },
            };
        }
        case ActionType.SetEditingExcess: {
            const value = action.value! as ExcessTypeEnum;
            return {
                ...state,
                EditingExcess: value,
            };
        }
        case ActionType.SetBuildingsExcess: {
            const value = action.value! as number;
            return {
                ...state,
                BuildingsExcessId: value,
            };
        }
        case ActionType.SetContentsExcess: {
            const value = action.value! as number;
            return {
                ...state,
                ContentsExcessId: value,
            };
        }
        case ActionType.SetEditingNoClaimsDiscount: {
            const value = action.value! as NoClaimsDiscountTypeEnum;
            return {
                ...state,
                EditingNoClaimsDiscount: value,
            };
        }
        case ActionType.SetBuildingsNoClaimsDiscount: {
            const value = action.value! as number;
            return {
                ...state,
                BuildingsNoClaimsDiscountId: value,
            };
        }
        case ActionType.SetContentsNoClaimsDiscount: {
            const value = action.value! as number;
            return {
                ...state,
                ContentsNoClaimsDiscountId: value,
            };
        }
        case ActionType.SetBuildingsAccidentalCover: {
            const value = action.value! as boolean;
            return {
                ...state,
                BuildingsAccidentalCover: value,
            };
        }
        case ActionType.SetContentsAccidentalCover: {
            const value = action.value! as boolean;
            return {
                ...state,
                ContentsAccidentalCover: value,
            };
        }
        case ActionType.SetHomeEmergencyCover: {
            const value = action.value! as boolean;
            return {
                ...state,
                HomeEmergencyCover: value,
            };
        }
        case ActionType.SetLegalProtectionCover: {
            const value = action.value! as boolean;
            return {
                ...state,
                LegalProtectionCover: value,
            };
        }
        case ActionType.SetPolicyDocumentsByEmail: {
            const value = action.value! as boolean;
            return {
                ...state,
                Apply: {
                    ...state.Apply,
                    ReceiveInsuranceDocumentsEmail: value,
                },
            };
        }
        case ActionType.SetPolicyStartDate: {
            const value = action.value! as string;
            return {
                ...state,
                Apply: {
                    ...state.Apply,
                    PolicyStartDate: value,
                },
            };
        }
        case ActionType.UpsertPersonalPossession: {
            const value = action.value! as {
                ItemType: string;
                ItemDescription: string;
                ItemValue: string;
                IncludeAtHome: boolean;
                IncludeAwayFromHome: boolean;
                IsEditing: boolean;
            };

            const cleanValue: PossessionsModel = {
                ...value,
                ItemValue: parseInt(value.ItemValue.toString().replace(/,/g, '')),
            };

            if (value.IsEditing) {
                return {
                    ...state,
                    HighValueItemEdit: undefined,
                    ContentsPersonalPossessions: state.ContentsPersonalPossessions.map((x) =>
                        x.IsEditing ? { ...cleanValue, IsEditing: false } : x,
                    ),
                };
            }

            return {
                ...state,
                ContentsPersonalPossessions: [...state.ContentsPersonalPossessions, cleanValue],
                HighValueItemEdit: undefined,
            };
        }
        case ActionType.SetEditingPersonalPossession: {
            const value = action.value! as PossessionsModel;

            return {
                ...state,
                HighValueItemEdit: undefined,
                ContentsPersonalPossessions: state.ContentsPersonalPossessions.map((x) =>
                    x === value ? { ...x, IsEditing: true } : { ...x, IsEditing: false },
                ),
            };
        }
        case ActionType.SetResetIsEditingPossessions: {
            return {
                ...state,
                HighValueItemEdit: undefined,
                ContentsPersonalPossessions: state.ContentsPersonalPossessions.map((x) => {
                    return { ...x, IsEditing: false };
                }),
            };
        }
        case ActionType.SetRemovingPersonalPossession: {
            return {
                ...state,
                ContentsPersonalPossessions: state.ContentsPersonalPossessions.filter(
                    (x) => !x.IsEditing,
                ),
            };
        }
        case ActionType.SetHighValueItemEdit: {
            const value = action.value! as PossessionsModel;
            return {
                ...state,
                HighValueItemEdit: value,
            };
        }
        case ActionType.SetPersonalPossessionValue: {
            const value = action.value! as string;
            return {
                ...state,
                ContentsPersonalUnspecifiedValue: value,
            };
        }
        case ActionType.AddFirstApplicant: {
            const value = action.value! as ApplicantDetailsModel;
            return {
                ...state,
                Client1: {
                    ...state.Client1,
                    Title: value.Title,
                    Forenames: value.Forenames,
                    Surname: value.Surname,
                    DateOfBirth: value.DateOfBirth,
                    EmailAddress: value.EmailAddress,
                    TelephoneNumber: value.TelephoneNumber,
                },
            };
        }
        case ActionType.AddSecondApplicant: {
            const value = action.value! as ApplicantDetailsModel;
            return {
                ...state,
                Client2: {
                    ...state.Client2,
                    Title: value.Title,
                    Forenames: value.Forenames,
                    Surname: value.Surname,
                    DateOfBirth: value.DateOfBirth,
                },
                IncludeSecondApplicant: true,
            };
        }
        case ActionType.RemoveSecondApplicant: {
            return {
                ...state,
                Client2: {
                    Title: '',
                    Forenames: '',
                    Surname: '',
                    DateOfBirth: '',
                    EmailAddress: '',
                    TelephoneNumber: '',
                },
                IncludeSecondApplicant: undefined,
            };
        }
        case ActionType.SetIncludeSecondApplicant: {
            const value = action.value! as boolean;
            return {
                ...state,
                Client2: {
                    Title: '',
                    Forenames: '',
                    Surname: '',
                    DateOfBirth: '',
                    EmailAddress: '',
                    TelephoneNumber: '',
                },
                IncludeSecondApplicant: value,
            };
        }
        case ActionType.UpdateQuoteReference: {
            return {
                ...state,
                SelectedQuote: action.value! as SelectPremiumModel,
            };
        }
        case ActionType.RefreshQuotes: {
            return {
                ...state,
                RefreshQuotes: state.RefreshQuotes + 1,
                RefreshingQuote: true,
            };
        }
        case ActionType.SetCachedResult: {
            const { key, value } = action.value! as any;
            return {
                ...state,
                QuoteResults: value,
                QuoteHistory: [...state.QuoteHistory, { key, value }],
            };
        }
        case ActionType.SetQuoteResults: {
            const value = action.value! as QuoteResultModel;
            let showNoQuotesModal = false;
            if (value.PremiumListItems && value.PremiumListItems.length == 0) {
                showNoQuotesModal = true;
            }
            return {
                ...state,
                QuoteResults: value,
                SelectedQuote: {
                    QuoteReference: value.QuoteReference,
                    PremiumData: { ...value.PremiumListItems[0] },
                },
                ShowNoQuotesModal: showNoQuotesModal,
                RefreshingQuote: false,
                QuoteError: false,
            };
        }
        case ActionType.SetEligibilityQuestions: {
            const value = action.value! as any;
            let endorsementQuestions = value.EndorsementQuestions;

            if (
                state.SelectedQuote.PremiumData.RequiresAdditionalEligibility &&
                value.AdditionalEligibilityQuestion &&
                value.AdditionalEligibilityQuestion !== ''
            ) {
                if (!endorsementQuestions) {
                    endorsementQuestions = [];
                }
                endorsementQuestions.push({
                    Number: 1,
                });
            }

            return {
                ...state,
                EligibilityQuestions: value.EligibilityQuestions,
                EndorsementQuestions: endorsementQuestions,
                EligibilityGrouping: groupEligibilityQuestions(value.EligibilityQuestions),
            };
        }
        case ActionType.SetIllustrationComplete: {
            const value = action.value! as boolean;
            return {
                ...state,
                IsIllustrationComplete: value,
            };
        }
        case ActionType.SetApplicationComplete: {
            const value = action.value! as boolean;
            return {
                ...state,
                IsApplicationComplete: value,
            };
        }
        case ActionType.SetShowNoQuoteModal: {
            const value = action.value! as boolean;
            return {
                ...state,
                ShowNoQuotesModal: value,
                QuoteError: true,
            };
        }
        case ActionType.SetShowReferModal: {
            const value = action.value! as boolean;
            return {
                ...state,
                ShowReferModal: value,
            };
        }
        case ActionType.SetShowContactModal: {
            const value = action.value! as boolean;
            return {
                ...state,
                ShowContactModal: value,
            };
        }
        case ActionType.SetApplicationStateToDefault: {
            return {
                ...ApplicationStateInitial,
            };
        }
        case ActionType.SetRetrievedQuote: {
            const value = action.value! as RetrieveQuoteResponse;

            let coverTypeOption: CoverTypeOption;
            if (value.IncludesBuildings && value.IncludesContents) {
                coverTypeOption = CoverTypeOption.BuildingsAndContentsCover;
            } else if (value.IncludesBuildings) {
                coverTypeOption = CoverTypeOption.BuildingsOnlyCover;
            } else if (value.IncludesContents) {
                coverTypeOption = CoverTypeOption.ContentsOnlyCover;
            }

            const quoteStatus = (RetrieveQuoteStatusEnum as any)[
                value.QuoteStatus.replace(' ', '')
            ];
            const isIllustrationComplete =
                quoteStatus === RetrieveQuoteStatusEnum.Illustration ||
                quoteStatus === RetrieveQuoteStatusEnum.ApplicationCompleted;

            return {
                ...state,
                Client1: {
                    ...value.Client1,
                    DateOfBirth: getFormattedDate(value.Client1.DateOfBirth.substring(0, 10)),
                },
                Client2: value.Client2
                    ? {
                          ...value.Client2,
                          DateOfBirth: getFormattedDate(value.Client2.DateOfBirth.substring(0, 10)),
                      }
                    : { ...ApplicantDetailsInitial },
                RiskAddress: value.RiskAddress,
                CorrespondenceAddress: value.CorrespondenceAddress,
                NumberOfBedrooms: value.NumberOfBedrooms.toString(),
                PropertyTypeId: (PropertyType as any)[value.PropertyType].toString(),
                YearBuiltRange: GetYearBuiltRange(value.YearBuilt),
                IncludeBuildings: value.IncludesBuildings,
                IncludeContents: value.IncludesContents,
                SelectedCoverType: coverTypeOption!,
                IncludeContentsPersonalPossessions: value.IncludesContentsPersonalPossessions,
                ContentsPersonalPossessions: value.SpecifiedItems,
                ContentsPersonalUnspecifiedValue: GetContentsPersonalUnspecifiedValue(
                    value.ContentsPersonalUnspecifiedValue,
                ),
                BuildingsExcessId: GetExcessId(value.BuildingsExcess),
                ContentsExcessId: GetExcessId(value.ContentsExcess),
                BuildingsNoClaimsDiscountId: (NoClaimsDiscountYearEnum as any)[
                    value.BuildingsClaimFreeYears
                ],
                ContentsNoClaimsDiscountId: (NoClaimsDiscountYearEnum as any)[
                    value.ContentsClaimFreeYears
                ],
                BuildingsAccidentalCover: value.IncludesBuildingsAccidentalCover,
                ContentsAccidentalCover: value.IncludesContentsAccidentalCover,
                HomeEmergencyCover: value.IncludesHomeEmergencyCover,
                LegalProtectionCover: value.IncludesFamilyLegalProtection,
                QuoteStatus: quoteStatus,
                QuoteResults: {
                    QuoteReference: value.QuoteReference,
                    Client1: value.Client1,
                    RiskAddress: value.RiskAddress,
                    CorrespondenceAddress: value.CorrespondenceAddress,
                    PremiumListItems: value.PremiumListItems,
                },
                SelectedQuote: {
                    QuoteReference: value.QuoteReference,
                    PremiumData: { ...value.PremiumListItems[0] },
                },
                IsIllustrationComplete: isIllustrationComplete,
            };
        }
        default:
            return state;
    }
}
