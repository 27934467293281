export enum ActionType {
    AddFirstApplicant,
    AddSecondApplicant,
    RemoveSecondApplicant,
    SetContactDetails,
    SetCorrespondenceAddress,
    UpdateAgreeDeclarations,
    UpdateAgreeDeclarationsGroup,
    UpdateQuoteReference,
    UpdateFullPropertyDetails,
    UpdateNumberOfBedrooms,
    UpdateTypeOfProperty,
    UpdateYearOfConstruction,
    UpdateAllAddressDetails,
    UpdateCoverType,
    UpdatePaymentDetails,
    SetEditingNoClaimsDiscount,
    SetBuildingsNoClaimsDiscount,
    SetContentsNoClaimsDiscount,
    SetEditingExcess,
    SetBuildingsExcess,
    SetContentsExcess,
    SetBuildingsAccidentalCover,
    SetContentsAccidentalCover,
    SetHomeEmergencyCover,
    SetLegalProtectionCover,
    UpsertPersonalPossession,
    SetEditingPersonalPossession,
    SetResetIsEditingPossessions,
    SetRemovingPersonalPossession,
    SetHighValueItemEdit,
    SetPersonalPossessionValue,
    SetIncludeSecondApplicant,
    SetPolicyDocumentsByEmail,
    SetPolicyStartDate,
    SetQuoteResults,
    SetCachedResult,
    RefreshQuotes,
    SetEligibilityQuestions,
    SetShowReferModal,
    SetShowNoQuoteModal,
    SetIllustrationComplete,
    SetShowContactModal,
    SetRiskAddressConfirmed,
    SetApplicationComplete,
    SetApplicationStateToDefault,
    SetRetrievedQuote,
}
