import { ApplicantDetailsModel } from '../Applicant/ApplicantDetailsModel';

export interface PaymentDetailsModel {
    HasStartDateWithin60Days: boolean;
    PolicyStartDate: string;
    MortgageProvider: string;
    MortgageRollNumber?: string;
    ThirdPartyReference?: string;
    SolicitorEmailAddress?: string;
    ReceiveInsuranceDocumentsEmail?: boolean;
    ApplyDirect: boolean;
    PaidYearly: boolean;
    Bank: {
        AccountName: string;
        AccountNumber: string;
        SortCode: string;
    };
    Payment: string;
    Client2?: ApplicantDetailsModel;
}

export const PaymentDetailsInitial = {
    HasStartDateWithin60Days: true,
    PolicyStartDate: '',
    MortgageProvider: '',
    MortgageRollNumber: '',
    ThirdPartyReference: '',
    SolicitorEmailAddress: '',
    ReceiveInsuranceDocumentsEmail: undefined,
    ApplyDirect: true,
    PaidYearly: true,
    Bank: {
        AccountName: '',
        AccountNumber: '',
        SortCode: '',
    },
    Payment: '',
};
