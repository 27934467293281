// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-checkboxes-tsx": () => import("../src/pages/checkboxes.tsx" /* webpackChunkName: "component---src-pages-checkboxes-tsx" */),
  "component---src-pages-cookiepolicy-tsx": () => import("../src/pages/cookiepolicy.tsx" /* webpackChunkName: "component---src-pages-cookiepolicy-tsx" */),
  "component---src-pages-direct-tsx": () => import("../src/pages/direct.tsx" /* webpackChunkName: "component---src-pages-direct-tsx" */),
  "component---src-pages-index-tsx": () => import("../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-loading-tsx": () => import("../src/pages/loading.tsx" /* webpackChunkName: "component---src-pages-loading-tsx" */),
  "component---src-pages-nhs-tsx": () => import("../src/pages/nhs.tsx" /* webpackChunkName: "component---src-pages-nhs-tsx" */),
  "component---src-pages-styles-radio-tsx": () => import("../src/pages/styles-radio.tsx" /* webpackChunkName: "component---src-pages-styles-radio-tsx" */),
  "component---src-pages-styles-tsx": () => import("../src/pages/styles.tsx" /* webpackChunkName: "component---src-pages-styles-tsx" */)
}

