import { createMuiTheme, CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import { graphql, StaticQuery } from 'gatsby';
import React from 'react';

interface InjectProviderProps {
    element: any;
}

declare module '@material-ui/core/styles/createMuiTheme' {
    interface Theme {
        verticalMargins: {
            headerBackgroundColour: string;
            footerBackgroundColour: string;
        };
        buttons: {
            primary: string;
            secondary?: string;
            destructive?: string;
        };
        typographyTheme: {
            headerTitleColour: string;
        };
        header: {
            backgroundColor: string;
            textColor: string;
            minHeight: number;
            fullWidth?: boolean;
        };
        quoteBar: {
            backgroundColor: string;
            borderColor: string;
            iconColor: string;
            textColor: string;
            showSubheader: boolean;
            subheaderText: string;
        };
        footer: {
            backgroundColor: string;
            textColor: string;
        };
        desktopMarginColour: string;
        logoVerticalAlignment: string;
        telephoneHelpVerticalAlignment: string;
        siteConfiguration: {
            coverOptions: {
                enableBuildingsCover: boolean;
                enableContentsCover: boolean;
            };
            skipQuoteResults?: boolean;
            showContactQuestionsBeforePremium?: boolean;
            insurerLogoGroup: {
                categorySet: string[];
            };
        };
        metadata: {
            phoneNumber: string;
            logoUrl: string;
            logo: {
                scale: number;
            };
            disableButtonDropShadows: boolean;
            favicons: [
                {
                    icon: {
                        asset: {
                            url: string;
                        };
                    };
                    size: number;
                },
            ];
            operatedByLogoUrl: string;
            homePageHero: {
                heroImageUrl: string;
                textColor: string;
                titleText: string;
                subheadingFontSize: number;
                subheadingText: string;
            };
            contentManagedLinks: {
                footerLinks: {
                    policyWordingUrl: string;
                    aboutOurInsuranceServicesUrl: string;
                    insuranceProductInformationDocumentUrl: string;
                    privacyPolicyUrl: string;
                };
                paymentDocuments: {
                    termsOfBusinessUrl: string;
                    premiumFinanceDocumentUrl: string;
                };
            };
        };
    }
    // allow configuration using `createMuiTheme`
    interface ThemeOptions {
        verticalMargins?: {
            headerBackgroundColour?: string;
            footerBackgroundColour?: string;
        };
        buttons?: {
            primary?: string;
            secondary?: string;
            destructive?: string;
        };
        typographyTheme?: {
            headerTitleColour?: string;
        };
        header?: {
            backgroundColor?: string;
            textColor?: string;
            minHeight?: number;
            fullWidth?: boolean;
        };
        quoteBar?: {
            backgroundColor?: string;
            borderColor?: string;
            iconColor: string;
            textColor?: string;
            showSubheader: boolean;
            subheaderText: string;
        };
        footer?: {
            backgroundColor?: string;
            textColor?: string;
        };
        desktopMarginColour: string;
        logoVerticalAlignment: string;
        telephoneHelpVerticalAlignment: string;
        siteConfiguration: {
            coverOptions: {
                enableBuildingsCover: boolean;
                enableContentsCover: boolean;
            };
            skipQuoteResults?: boolean;
            showContactQuestionsBeforePremium?: boolean;
            insurerLogoGroup: {
                categorySet: string[];
            };
        };
        metadata?: {
            phoneNumber?: string;
            logoUrl?: string;
            logo: {
                scale: number;
            };
            disableButtonDropShadows?: boolean;
            favicons?: [
                {
                    icon?: {
                        asset?: {
                            url?: string;
                        };
                    };
                    size?: number;
                },
            ];
            operatedByLogoUrl?: string;
            homePageHero?: {
                heroImageUrl?: string;
                textColor?: string;
                titleText?: string;
                subheadingFontSize?: number;
                subheadingText?: string;
            };
            contentManagedLinks: {
                footerLinks: {
                    policyWordingUrl: string;
                    aboutOurInsuranceServicesUrl: string;
                    insuranceProductInformationDocumentUrl: string;
                    privacyPolicyUrl: string;
                };
                paymentDocuments: {
                    termsOfBusinessUrl: string;
                    premiumFinanceDocumentUrl: string;
                };
            };
        };
    }
}

const createTheme = (data: any, allInsurers: string[]) => {
    const theme = data[0].theme;
    const dataElement = data[0];

    return createMuiTheme({
        overrides: {
            MuiInputBase: {
                input: {
                    '&:-webkit-autofill': {
                        transitionDelay: '9999s',
                        transitionProperty: 'background-color, color',
                    },
                },
            },
            ...(theme.typography.forceUpperCaseH1 && {
                MuiTypography: {
                    h1: {
                        textTransform: 'uppercase',
                    },
                },
            }),
            ...(theme.typography.forceUpperCaseButton && {
                MuiButton: {
                    label: {
                        textTransform: 'uppercase',
                    },
                },
            }),
            MuiPopover: {
                paper: {
                    borderRadius: 5,
                },
            },
        },
        siteConfiguration: {
            coverOptions: {
                enableBuildingsCover:
                    dataElement.configurationOptions.coverOptions.enableBuildingsCover,
                enableContentsCover:
                    dataElement.configurationOptions.coverOptions.enableContentsCover,
            },
            skipQuoteResults: dataElement.configurationOptions.skipQuoteResults || false,
            showContactQuestionsBeforePremium:
                dataElement.configurationOptions.showContactQuestionsBeforePremium || false,
            insurerLogoGroup: {
                categorySet:
                    dataElement.configurationOptions.insurerLogoGroup?.categorySet || allInsurers,
            },
        },
        palette: {
            primary: {
                light: theme.palette.primary.light.hex,
                main: theme.palette.primary.main.hex,
                dark: theme.palette.primary.dark.hex,
                contrastText: theme.palette.primary.contrastText.hex,
            },
            secondary: {
                light: theme.palette.secondary.light.hex,
                main: theme.palette.secondary.main.hex,
                dark: theme.palette.secondary.dark.hex,
                contrastText: theme.palette.secondary.contrastText.hex,
            },
            error: {
                light: theme.palette.error.light.hex,
                main: theme.palette.error.main.hex,
                dark: theme.palette.error.dark.hex,
                contrastText: theme.palette.error.contrastText.hex,
            },
            text: {
                primary: theme.palette.text.primary.hex,
                secondary: theme.palette.text.secondary.hex,
                disabled: theme.palette.text.disabled.hex,
                hint: theme.palette.text.hint.hex,
            },
            divider: theme.palette.divider.hex,
            background: {
                default: theme.palette.background.default.hex,
                paper: theme.palette.background.paper.hex,
            },
            action: {
                active: theme.palette.action.active.hex,
                hover: theme.palette.action.hover.hex,
                hoverOpacity: theme.palette.action.hoverOpacity,
                selected: theme.palette.action.selected.hex,
                disabled: theme.palette.action.disabled.hex,
                disabledBackground: theme.palette.action.disabledBackground.hex,
            },
        },
        shape: {
            borderRadius: theme.shape.borderRadius,
        },
        buttons: {
            primary: theme.palette.button.primary.hex,
            secondary: theme.palette.button.secondary.hex,
            destructive: theme.palette.button.destructive.hex,
        },
        verticalMargins: {
            headerBackgroundColour: theme.headerBackgroundColour.hex,
            footerBackgroundColour: theme.footerBackgroundColour.hex,
        },
        desktopMarginColour: theme.palette.background.desktopMarginColour.hex,
        typography: {
            fontFamily: '"Spartan MB", "Roboto", "Helvetica", "Arial", "sans-serif"',
            fontSize: 16,
        },
        header: {
            backgroundColor: theme.headerBackgroundColour.hex,
            textColor: theme.headerTextColour.hex,
            minHeight: theme.headerHeight,
            fullWidth: theme.fullWidthHeader,
        },
        quoteBar: {
            backgroundColor: theme.quoteBar.quoteBarBackgroundColour.hex,
            borderColor: theme.quoteBar.quoteBarBorderColour.hex,
            iconColor: theme.quoteBar.quoteBarIconColour.hex,
            textColor: theme.quoteBar.quoteBarTextColour.hex,
            showSubheader: theme.quoteBar.showSubheader,
            subheaderText: theme.quoteBar.subheaderText,
        },
        footer: {
            backgroundColor: theme.footerBackgroundColour.hex,
            textColor: theme.footerTextColour.hex,
        },
        logoVerticalAlignment: dataElement.logoVerticalAlignment,
        telephoneHelpVerticalAlignment: dataElement.telephoneHelpVerticalAlignment,
        metadata: {
            phoneNumber: dataElement.phoneNumber,
            logoUrl: dataElement.logo.asset.url,
            logo: {
                scale: dataElement.logoScale,
            },
            disableButtonDropShadows: theme.shape.disableButtonDropShadow,
            favicons: dataElement.favicons,
            operatedByLogoUrl: dataElement.operatedByLogo?.asset?.url,
            homePageHero: {
                heroImageUrl: dataElement.homePageHero.heroImage.asset.url,
                textColor: dataElement.homePageHero.textColor.hex,
                titleText: dataElement.homePageHero.titleText,
                subheadingFontSize: dataElement.homePageHero.subheadingFontSize,
                subheadingText: dataElement.homePageHero.subheadingText,
            },
            contentManagedLinks: {
                footerLinks: {
                    policyWordingUrl: dataElement.contentManagedLinks.footerLinks.policyWordingUrl,
                    aboutOurInsuranceServicesUrl:
                        dataElement.contentManagedLinks.footerLinks.aboutOurInsuranceServicesUrl,
                    insuranceProductInformationDocumentUrl:
                        dataElement.contentManagedLinks.footerLinks
                            .insuranceProductInformationDocumentUrl,
                    privacyPolicyUrl: dataElement.contentManagedLinks.footerLinks.privacyPolicyUrl,
                },
                paymentDocuments: {
                    termsOfBusinessUrl:
                        dataElement.contentManagedLinks.paymentDocuments.termsOfBusinessUrl,
                    premiumFinanceDocumentUrl:
                        dataElement.contentManagedLinks.paymentDocuments.premiumFinanceDocumentUrl,
                },
            },
        },
    });
};

const InjectProvider = ({ element }: InjectProviderProps) => {
    return (
        <StaticQuery
            query={graphql`
                query mq {
                    allSanityInsurerGroup(filter: { insurerGroupName: { eq: "All" } }) {
                        nodes {
                            insurerGroupName
                            categorySet
                        }
                    }
                    allSanitySite {
                        nodes {
                            _id
                            companyName
                            favicons {
                                icon {
                                    asset {
                                        url
                                    }
                                }
                                size
                            }
                            homePageHero {
                                heroImage {
                                    asset {
                                        url
                                    }
                                }
                                textColor {
                                    hex
                                }
                                titleText
                                subheadingFontSize
                                subheadingText
                            }
                            theme {
                                palette {
                                    primary {
                                        contrastText {
                                            hex
                                        }
                                        dark {
                                            hex
                                        }
                                        light {
                                            hex
                                        }
                                        main {
                                            hex
                                        }
                                    }
                                    secondary {
                                        contrastText {
                                            hex
                                        }
                                        dark {
                                            hex
                                        }
                                        light {
                                            hex
                                        }
                                        main {
                                            hex
                                        }
                                    }
                                    error {
                                        contrastText {
                                            hex
                                        }
                                        dark {
                                            hex
                                        }
                                        light {
                                            hex
                                        }
                                        main {
                                            hex
                                        }
                                    }
                                    background {
                                        paper {
                                            hex
                                        }
                                        default {
                                            hex
                                        }
                                        desktopMarginColour {
                                            hex
                                        }
                                    }
                                    divider {
                                        hex
                                    }
                                    action {
                                        active {
                                            hex
                                        }
                                        disabled {
                                            hex
                                        }
                                        disabledBackground {
                                            hex
                                        }
                                        hover {
                                            hex
                                        }
                                        hoverOpacity
                                        selected {
                                            hex
                                        }
                                    }
                                    button {
                                        destructive {
                                            hex
                                        }
                                        primary {
                                            hex
                                        }
                                        secondary {
                                            hex
                                        }
                                    }
                                    divider {
                                        hex
                                    }
                                    text {
                                        disabled {
                                            hex
                                        }
                                        hint {
                                            hex
                                        }
                                        primary {
                                            hex
                                        }
                                        secondary {
                                            hex
                                        }
                                    }
                                }
                                headerBackgroundColour {
                                    hex
                                }
                                headerTextColour {
                                    hex
                                }
                                fullWidthHeader
                                headerHeight
                                quoteBar {
                                    quoteBarTextColour {
                                        hex
                                    }
                                    quoteBarBorderColour {
                                        hex
                                    }
                                    quoteBarIconColour {
                                        hex
                                    }
                                    quoteBarBackgroundColour {
                                        hex
                                    }
                                    showSubheader
                                    subheaderText
                                }
                                footerTextColour {
                                    hex
                                }
                                footerBackgroundColour {
                                    hex
                                }
                                typography {
                                    HeaderTitleColour {
                                        hex
                                    }
                                    forceUpperCaseButton
                                    forceUpperCaseH1
                                }
                                shape {
                                    borderRadius
                                    disableButtonDropShadow
                                }
                            }
                            phoneNumber
                            logo {
                                asset {
                                    url
                                }
                            }
                            logoScale
                            operatedByLogo {
                                asset {
                                    url
                                }
                            }
                            contentManagedLinks {
                                footerLinks {
                                    privacyPolicyUrl
                                    policyWordingUrl
                                    insuranceProductInformationDocumentUrl
                                    aboutOurInsuranceServicesUrl
                                }
                                paymentDocuments {
                                    termsOfBusinessUrl
                                    premiumFinanceDocumentUrl
                                }
                            }
                            telephoneHelpVerticalAlignment
                            logoVerticalAlignment
                            configurationOptions {
                                coverOptions {
                                    enableBuildingsCover
                                    enableContentsCover
                                }
                                skipQuoteResults
                                showContactQuestionsBeforePremium
                                insurerLogoGroup {
                                    categorySet
                                }
                            }
                        }
                    }
                }
            `}
            render={(data) => {
                const siteTheme =
                    process.env.SANITY_SITE_ID === '$(siteId)'
                        ? data.allSanitySite.nodes.filter((x: any) => x.companyName === '_default')
                        : data.allSanitySite.nodes.filter(
                              (x: any) => x._id == process.env.SANITY_SITE_ID,
                          );

                const allInsurers = data.allSanityInsurerGroup.nodes[0].categorySet;

                return (
                    <ThemeProvider theme={createTheme(siteTheme, allInsurers)}>
                        <CssBaseline />
                        {element}
                    </ThemeProvider>
                );
            }}
        />
    );
};
export default InjectProvider;
