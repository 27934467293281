import { AddressDetailsModel } from "../Address/AddressDetailsModel";
import { ApplicantDetailsModel } from "../Applicant/ApplicantDetailsModel";
import { PremiumListItem } from "../QuoteResults/SelectPremiumModel";
import { PossessionsModel } from "../Possessions/PossessionsModel";
import ExcessList from "../Excess/ExcessList";
import { InitialQuoteDetails } from "../../models/QuoteDetailsState";

export interface RetrieveQuoteResponse {
    QuoteReference: string,
    Client1: ApplicantDetailsModel;
    Client2: ApplicantDetailsModel | undefined;
    RiskAddress: AddressDetailsModel;
    CorrespondenceAddress: AddressDetailsModel;
    PropertyType: string;
    NumberOfBedrooms: number;
    YearBuilt: number;
    IsFirstTimeBuyer: boolean;
    IncludesBuildings: boolean;
    BuildingsCover: number;
    IncludesBuildingsAccidentalCover: boolean;
    BuildingsClaimFreeYears: string;
    IncludesContents: boolean;
    ContentsCover: number;
    IncludesContentsAccidentalCover: boolean;
    IncludesContentsPersonalPossessions: boolean;
    ContentsClaimFreeYears: string;
    SpecifiedItems: PossessionsModel[];
    ContentsPersonalUnspecifiedValue: number;
    BuildingsExcess: number;
    ContentsExcess: number;
    QuoteStatus: string;
    IncludesFamilyLegalProtection: boolean;
    FamilyLegalProtectionPremium: number;
    IncludesHomeEmergencyCover: boolean;
    HomeEmergencyCoverPremium: number;
    PremiumListItems: PremiumListItem[];
};

export const GetYearBuiltRange = (yearBuilt: number): string => {
    if (yearBuilt < 1750)
        return "Pre1750";
    if (yearBuilt >= 1750 && yearBuilt < 1870)
        return "_1750to1869";
    if (yearBuilt >= 1870 && yearBuilt < 1900)
        return "_1870to1899";
    if (yearBuilt >= 1900 && yearBuilt < 1920)
        return "_1900to1919";
    if (yearBuilt >= 1920 && yearBuilt < 1946)
        return "_1920to1945";
    if (yearBuilt >= 1946 && yearBuilt < 1960)
        return "_1946to1959";
    if (yearBuilt >= 1960 && yearBuilt < 1980)
        return "_1960to1979";
    if (yearBuilt >= 1980 && yearBuilt < 1990)
        return "_1980to1989";
    if (yearBuilt >= 1990 && yearBuilt < 2000)
        return "_1990to1999";
    if (yearBuilt >= 2000 && yearBuilt < 2010)
        return "_2000to2009";
    if (yearBuilt >= 2010 && yearBuilt < 2020)
        return "_2010to2019";
    if (yearBuilt >= 2020)
        return "_2020onwards";

    return "";
};

export const GetExcessId = (excess: number): number => {
    const excessItem = ExcessList.find((item) => item.Value === `£${excess}`);
    return (excessItem && excessItem!.Id) || InitialQuoteDetails.BuildingsExcessId;
};

export const GetContentsPersonalUnspecifiedValue = (value: number) => {
    return value === 0 ? "" : value.toString();
};