const getFormattedDate = (inputDate: string | Date) => {
    const date = new Date(inputDate);
    var dd = String(date.getDate()).padStart(2, '0');
    var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = date.getFullYear();
    return dd + '/' + mm + '/' + yyyy;
};

const getDateFromString = (input: string) => {
    if (input) {
        const values = input.replace(/ /g, '').split('/');
        return new Date(values[2] + '-' + values[1] + '-' + values[0]);
    }
    return null;
};

const formatPostcode = (postcode: string) => {
    if (typeof postcode !== 'string') {
        return '';
    }
    const postcodeSplit = postcode.replace(/ /g, "").split("");
    postcodeSplit.splice(-3, 0, " ");
    return postcodeSplit.join('').toUpperCase();;
};

const calculateAge = (value: any) => {
    const dateValue = getDateFromString(value)
    if (dateValue) {
        const differenceInMilliseconds = Date.now() - dateValue.getTime();
        const ageInTimeSpan = new Date(differenceInMilliseconds); // miliseconds from epoch
        return Math.abs(ageInTimeSpan.getUTCFullYear() - 1970);
    }
    return 18;
};

export { getFormattedDate, getDateFromString, formatPostcode, calculateAge };