import { ApplicantDetailsModel } from "../Applicant/ApplicantDetailsModel";
import { AddressDetailsModel } from "../Address/AddressDetailsModel";

export interface SelectPremiumModel {
    QuoteReference: string;
    PremiumData: PremiumListItem;
};

export interface PremiumListItem {
    TotalMonthlyPremium?: number;
    TotalAnnualPremium?: number;
    PremiumId?: number;
    Provider: string;
    RequiresAdditionalEligibility: boolean;
};

export const SelectPremiumInitial: SelectPremiumModel = {
    QuoteReference: '',
    PremiumData: {
        Provider: '',
        RequiresAdditionalEligibility: false,
    },
};

export interface QuoteResultModel {
    QuoteReference: string;
    Client1: ApplicantDetailsModel;
    CorrespondenceAddress: AddressDetailsModel;
    RiskAddress: AddressDetailsModel;
    PremiumListItems: PremiumListItem[];
};