import React, { createContext, Dispatch, useContext, useReducer } from 'react';
import { Action } from '../models/Action';
import { ApplicationState } from '../models/ApplicationState';

interface ContextProps {
    state: ApplicationState;
    dispatch: Dispatch<Action>;
}

interface StateProviderProps {
    reducer: (state: ApplicationState, action: Action) => ApplicationState;
    initialState: ApplicationState;
    children: any;
}

export const StateContext = createContext({} as ContextProps);

export const StateProvider = ({ reducer, initialState, children }: StateProviderProps) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const value = { state, dispatch };

    return <StateContext.Provider value={value}>{children}</StateContext.Provider>;
};

export const useStateValue = () => useContext(StateContext);
