const ExcessList = [
    { Id: 1, Value: "£100" },
    { Id: 2, Value: "£150" },
    { Id: 3, Value: "£200" },
    { Id: 4, Value: "£250" },
    { Id: 5, Value: "£300" },
    { Id: 6, Value: "£350" },
    { Id: 7, Value: "£400" },
    { Id: 8, Value: "£450" },
    { Id: 9, Value: "£500" },
];

export default ExcessList;