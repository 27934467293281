import { EligibilityQuestionGroup } from '../components/Eligibility/EligibilityQuestionsModel';
import { ExcessTypeEnum } from '../components/Excess/ExcessTypeEnum';
import { NoClaimsDiscountTypeEnum } from '../components/NoClaimsDiscount/NoClaimsDiscountTypeEnum';
import {
    PaymentDetailsInitial,
    PaymentDetailsModel,
} from '../components/Payment/PaymentDetailsModel';
import { PossessionsModel } from '../components/Possessions/PossessionsModel';
import {
    QuoteResultModel,
    SelectPremiumInitial,
    SelectPremiumModel,
} from '../components/QuoteResults/SelectPremiumModel';
import { RetrieveQuoteStatusEnum } from '../components/RetrieveQuote/RetrieveQuoteStatusEnum';
import { InitialQuoteDetails, QuoteDetailsState } from './QuoteDetailsState';

export interface ApplicationState extends QuoteDetailsState {
    HighValueItemEdit: PossessionsModel | undefined;
    EditingExcess: ExcessTypeEnum; // 0 = buildings, 1 = contents
    EditingNoClaimsDiscount: NoClaimsDiscountTypeEnum;
    AgreeDeclarations: boolean | undefined;
    SelectedQuote: SelectPremiumModel;
    Apply: PaymentDetailsModel;
    IncludeSecondApplicant: boolean | undefined;
    QuoteResults: QuoteResultModel | undefined;
    QuoteHistory: any;
    ReloadAddress: boolean;
    RefreshQuotes: number;
    EligibilityQuestions: any;
    EligibilityGrouping: EligibilityQuestionGroup[];
    EndorsementQuestions: any;
    ShowNoQuotesModal: boolean;
    IsIllustrationComplete: boolean;
    ShowReferModal: boolean;
    ShowContactModal: boolean;
    RefreshingQuote: boolean;
    QuoteError: boolean;
    IsRiskAddressConfirmed: boolean;
    IsApplicationComplete: boolean;
    QuoteStatus: RetrieveQuoteStatusEnum | undefined;
}

export const ApplicationStateInitial: ApplicationState = {
    ...InitialQuoteDetails,
    HighValueItemEdit: undefined,
    EditingExcess: ExcessTypeEnum.BuildingsExcess,
    EditingNoClaimsDiscount: NoClaimsDiscountTypeEnum.BuildingsNCD,
    AgreeDeclarations: undefined,
    SelectedQuote: SelectPremiumInitial,
    Apply: PaymentDetailsInitial,
    IncludeSecondApplicant: undefined,
    QuoteResults: undefined,
    QuoteHistory: [],
    ReloadAddress: true,
    RefreshQuotes: 0,
    EligibilityQuestions: undefined,
    EligibilityGrouping: [],
    EndorsementQuestions: undefined,
    ShowNoQuotesModal: false,
    IsIllustrationComplete: false,
    ShowReferModal: false,
    ShowContactModal: false,
    RefreshingQuote: false,
    QuoteError: false,
    IsRiskAddressConfirmed: false,
    IsApplicationComplete: false,
    QuoteStatus: undefined,
};
