export enum PropertySetting {
    NumberOfBedrooms,
    TypeOfProperty,
    YearOfConstruction,
};

export enum PropertyType {
    DetachedHouse = 10,
    SemiDetachedHouse = 20,
    TerracedHouse = 30,
    DetachedBungalow = 50,
    SemiDetachedBungalow = 60,
    PurposeBuiltFlat = 80,
    TerracedBungalow = 160,
    CoachHouse = 200,
};

export enum BuildingsAndContentsPropertyType {
    DetachedHouse = "Detached House",
    SemiDetachedHouse = "Semi Detached House",
    TerracedHouse = "Terraced House",
    DetachedBungalow = "Detached Bungalow",
    SemiDetachedBungalow = "Semi Detached Bungalow",
    TerracedBungalow = "Terraced Bungalow",
    PurposeBuiltFlat = "Flat or Maisonette",
    CoachHouse = "Coach House",
};

export enum YearBuiltRange {
    Pre1750 = "Pre 1750",
    _1750to1869 = "Between 1750 and 1869",
    _1870to1899 = "Between 1870 and 1899",
    _1900to1919 = "Between 1900 and 1919",
    _1920to1945 = "Between 1920 and 1945",
    _1946to1959 = "Between 1946 and 1959",
    _1960to1979 = "Between 1960 and 1979",
    _1980to1989 = "Between 1980 and 1989",
    _1990to1999 = "Between 1990 and 1999",
    _2000to2009 = "Between 2000 and 2009",
    _2010to2019 = "Between 2010 and 2019 ",
    _2020onwards = "2020 onwards" // Send 2019
};