export enum AddressType {
    RiskAddress,
    CorrespondenceAddress,
};

export interface AddressDetailsModel {
    AddressLine1: string;
    AddressLine2: string;
    AddressLine3: string;
    AddressLine4: string;
    Postcode: string;
};

export const AddressDetailsInitial: AddressDetailsModel = {
    AddressLine1: '',
    AddressLine2: '',
    AddressLine3: '',
    AddressLine4: '',
    Postcode: '',
};

export interface AllAddressDetails {
    RiskAddress: AddressDetailsModel;
    CorrespondenceAddress: AddressDetailsModel;
};

export const AllAddressDetailsInitial: AllAddressDetails = {
    RiskAddress: { ...AddressDetailsInitial },
    CorrespondenceAddress: { ...AddressDetailsInitial },
};
